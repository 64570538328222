import React, { useEffect, useRef } from "react";
import InputField from "../../components/Custom/Input/InputField";
import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import Image2 from "../../images/Rectangle 3.png";
import CustomButton from "../../components/Custom/Button/CustomButton";
import { SignUpFormSchema } from "./SignUpFormSchema";
import { createUserActivity } from "../../redux/Slices/userActivitySlice"; // Import the thunk
import { connect } from "react-redux";
import { storage } from "../../config/storage";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { register } from "../../redux/Slices/userSlice";
import { toast } from "react-toastify";

// Define the Login component
const SignUp = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActivityCreated = useRef(false);
    const sessionKey = storage.fetchFromSessionStorage.sessionKey();

    useEffect(() => {
        const data = {
            sessionKey: sessionKey,
            location: location,
        };
        if (!isActivityCreated.current) {
            props.createUserActivity(data); // Dispatch the thunk using props
            isActivityCreated.current = true;
        }
        return () => {
            toast.dismiss();
        };
    }, []);

    const handleSubmit = (values) => {
        const { confirmPassword, ...filteredValues } = values;

        // Add the sessionKey to the filteredValues object
        Object.assign(filteredValues, { sessionKey: sessionKey });

        // Send the registration data
        props
            .sendRegister(filteredValues)
            .then((resultAction) => {
                if (register.fulfilled.match(resultAction)) {
                    // Navigate on successful registration
                    navigate("/profile");
                } else {
                    // Optionally handle the case where registration fails
                    console.log(resultAction.payload); // This will be the error message
                }
            })
            .catch((error) => {
                console.error("Error during registration:", error);
                toast.error("An error occurred. Please try again later.");
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} style={Style.grid}>
                    <Paper sx={Style.paper}>
                        <Typography
                            variant="h4"
                            align="center"
                            gutterBottom
                            sx={Style.typography}
                        >
                            REGISTER
                        </Typography>
                        <Formik
                            initialValues={{
                                userName: "",
                                userEmail: "",
                                userPassword: "",
                                confirmPassword: "",
                            }}
                            validationSchema={SignUpFormSchema.SignUp}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                userName,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Box>
                                        <InputField
                                            label="Name"
                                            variant="outlined"
                                            size="small"
                                            name="userName"
                                            value={values.userName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.userName &&
                                                Boolean(errors.userName)
                                            }
                                            helperText={
                                                touched.userName &&
                                                errors.userName
                                            }
                                            FormHelperTextProps={{
                                                sx: {
                                                    marginLeft: "0px",
                                                },
                                            }}
                                        />
                                    </Box>

                                    <Box sx={Style.box}>
                                        <InputField
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            name="userEmail"
                                            type="email"
                                            value={values.userEmail}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.userEmail &&
                                                Boolean(errors.userEmail)
                                            }
                                            helperText={
                                                touched.userEmail &&
                                                errors.userEmail
                                            }
                                            FormHelperTextProps={{
                                                sx: {
                                                    marginLeft: "0px",
                                                },
                                            }}
                                        />
                                    </Box>

                                    <Box sx={Style.box}>
                                        <InputField
                                            label="Password"
                                            variant="outlined"
                                            size="small"
                                            name="userPassword"
                                            type="password"
                                            value={values.userPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.userPassword &&
                                                Boolean(errors.userPassword)
                                            }
                                            helperText={
                                                touched.userPassword &&
                                                errors.userPassword
                                            }
                                            FormHelperTextProps={{
                                                sx: {
                                                    marginLeft: "0px",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={Style.box}>
                                        <InputField
                                            label="Confirm Password"
                                            variant="outlined"
                                            size="small"
                                            name="confirmPassword"
                                            type="password"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.confirmPassword &&
                                                Boolean(errors.confirmPassword)
                                            }
                                            helperText={
                                                touched.confirmPassword &&
                                                errors.confirmPassword
                                            }
                                            FormHelperTextProps={{
                                                sx: {
                                                    marginLeft: "0px",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={Style.box1}>
                                        <CustomButton
                                            type="submit"
                                            children={"Register"}
                                        />
                                    </Box>
                                    <Box sx={Style.box2}>
                                        <Typography variant="body2">
                                            Already Registered?{" "}
                                            <Link
                                                to="/login"
                                                style={Style.link}
                                            >
                                                Login
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} sx={Style.grid2}>
                    <img
                        src={Image2}
                        alt="Login Illustration"
                        style={Style.img1}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    createUserActivity: (data) => dispatch(createUserActivity(data)), // Map the createUserActivity thunk to props
    sendRegister: (data) => dispatch(register(data)), // Map the register thunk to props
});

// Connect the AboutUs component to the Redux store
export default connect(null, mapDispatchToProps)(SignUp);

const Style = {
    grid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    paper: {
        marginTop: { xs: 20, lg: "0%" },
        padding: "20px",
        maxWidth: "400px",
        width: "100%",
        elevation: 8,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)", // Set equal shadow on all sides
    },

    box: {
        mt: "20px",
    },

    box1: {
        mt: "30px",
        textAlign: "center",
    },

    box2: {
        mt: "20px",
        textAlign: "center",
    },

    link: {
        color: "#1976d2",
        textDecoration: "none",
    },

    grid2: {
        display: { xs: "none", md: "flex" },
    },

    img1: {
        width: "100%",
        height: "100vh",
        objectFit: "cover",
    },

    typography: {
        marginBottom: "30px",
    },
};
