import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

// InputField component renders a customizable TextField with common props
const InputField = ({
    label = "",
    variant = "outlined",
    size = "medium",
    value = "",
    onChange = () => {},
    type = "",
    ...props
}) => {
    return (
        <TextField
            fullWidth
            label={label}
            variant={variant}
            size={size}
            value={value}
            onChange={onChange}
            type={type}
            {...props}
        />
    );
};

// Define prop types for the InputField component
InputField.propTypes = {
    label: PropTypes.string,
    variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
    size: PropTypes.oneOf(["small", "medium"]),
    value: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
};

export default InputField;
