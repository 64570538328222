import { Api } from "../config/request";

// API function to log user activity
const requestCreateActivity = async (data) => {
    let response = await Api.PostRequest("activityDetail/createActivity", data);
    return response;
};

const activityApi = {
    requestCreateActivity,
};

export default activityApi;
