import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NavbarIcons from "./NavbarIcons";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

const drawerWidth = 240;

const navItems = [
    { text: "Home", path: "/home" },
    { text: "About Us", path: "/aboutUs" },
    { text: "Contact Us", path: "/contactUs" },
];
// Navbar component renders the main navigation bar with responsive drawer for mobile view
const Navbar = (props) => {
    const { window, btn_name } = props; // Destructure props

    const [mobileOpen, setMobileOpen] = React.useState(false); // State for mobile drawer toggle

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    // Drawer content for mobile view
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={Style.Box1}>
            <Typography variant="h6" sx={Style.HeaderText}>
                <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "40px", width: "auto" }}
                />
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton
                            sx={Style.ListItemStyle}
                            component={Link}
                            to={item.path}
                        >
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    // Container for responsive drawer handling
    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={Style.MainBox}>
            <CssBaseline />
            <AppBar component="nav" sx={Style.AppBar1}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={Style.IconStyle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={Style.Typography1}
                    >
                        <img
                            src={logo}
                            alt="Logo"
                            style={{ height: "40px", width: "auto" }}
                        />
                    </Typography>
                    <Box sx={Style.NavBtns}>
                        {navItems.map((item) => (
                            <Button
                                key={item.text}
                                sx={Style.NavBtns1}
                                component={Link}
                                to={item.path}
                            >
                                {item.text}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={Style.NavBtnContainer}>
                        <NavbarIcons btn_name={"LOGIN"} />
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={Style.Drawer1}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
};
// PropTypes for Navbar component
Navbar.propTypes = {
    window: PropTypes.func,
    btn_name: PropTypes.string,
};

export default Navbar;
// Style object for Navbar component
const Style = {
    Drawer1: {
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
        },
    },

    NavBtns1: {
        color: "black",
        mr: { xs: "", md: 10 },
    },

    NavBtns: {
        display: { xs: "none", sm: "block" },
    },

    AppBar1: {
        backgroundColor: "white",
    },

    HeaderText: {
        my: 2,
    },

    MainBox: {
        display: "flex",
    },

    Box1: {
        textAlign: "center",
    },

    IconStyle: {
        mr: 2,
        display: { sm: "none" },
        color: "black",
    },

    ListItemStyle: {
        textAlign: "center",
    },

    imgP: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
    },

    Typography1: {
        flexGrow: 1,
        display: { xs: "none", sm: "block" },
        color: "black",
        fontWeight: "bold",
    },

    NavBtnContainer: {
        marginLeft: "auto",
    },
};
