// src/redux/Slices/createSlices.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "../../services/userApi";
import handleAsyncThunk from "../handleAsyncThunk";
import sessionApi from "../../services/sessionApi";
import { storage } from "../../config/storage";

export const session = createAsyncThunk("auth/session", async (data) => {
    const response = await sessionApi.requestCreateSession(data);
    if (response.data.status == 0)
        storage.setInSessionStorage.sessionKey(data.sessionKey);
    return response.data;
});

const sessionSlice = createSlice({
    name: "session",
    initialState: {
        isSession: false,
    },

    extraReducers: (builder) => {
        handleAsyncThunk(builder, session, ""); // Use builder notation
    },
});

export const { setIsSession } = sessionSlice.actions;

export default sessionSlice.reducer;
