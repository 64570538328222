// src/redux/Slices/userActivitySlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import activityApi from "../../services/activityApi";
import handleAsyncThunk from "../handleAsyncThunk";
import activityCodes from "../../constants/activityCodes";

// Define the async thunk for sending user activity
export const createUserActivity = createAsyncThunk(
    "userActivity/createUserActivity",
    async (data) => {
        const { sessionKey, location } = data;
        const pageDescription = location.pathname; // Adjust this based on your need

        const activityDetails = {
            actionType: activityCodes.pageViewed, // Example activity code, replace with your constant
            pageName: pageDescription,
        };

        const requestData = {
            sessionKey,
            activityDetails: JSON.stringify(activityDetails),
        };

        // Call the API to send user activity

        const response = await activityApi.requestCreateActivity(requestData);
        return response.data;
    }
);

const userActivitySlice = createSlice({
    name: "userActivity",
    initialState: {},
    extraReducers: (builder) => {
        handleAsyncThunk(builder, createUserActivity); // Use handleAsyncThunk here
    },
});

export default userActivitySlice.reducer;
