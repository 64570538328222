import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../pages/Login/Login";
import Home from "../pages/Home/Home";
import ContactUs from "../pages/ContactUs/ContactUs";
import Profile from "../pages/Profile/Profile";
import AboutUs from "../pages/AboutUs/AboutUs";
import ProtectedRoutes from "./ProtectedRoutes";
import ToastContainer from "../components/Toaster/CustomToastContainer";
import SignUp from "../pages/SignUp/SignUp";
import Navbar from "../components/Navbar/Navbar";

// Defines the main routes for the application.
const MainRoutes = () => {
    return (
        <>
            <Navbar />
            <ToastContainer />
            <Routes>
                <Route path="home" element={<Home />} />
                <Route path="signUp" element={<SignUp />} />
                <Route path="logIn" element={<Login />} />
                <Route path="contactUs" element={<ContactUs />} />
                <Route path="aboutUs" element={<AboutUs />} />
                <Route element={<ProtectedRoutes />}>
                    <Route path="profile" element={<Profile />} />
                </Route>
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        </>
    );
};

export default MainRoutes;
