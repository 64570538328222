import * as React from "react";
import { Avatar, Button, Menu, MenuItem, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useNavigate } from "react-router-dom";
import { storage } from "../../config/storage";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { logout } from "../../redux/Slices/userSlice";
import { connect } from "react-redux";
import { session } from "../../redux/Slices/sessionSlice";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const NavbarIcons = (props) => {
    const sessionKey = storage.fetchFromSessionStorage.sessionKey();
    const auth = storage.fetchFromLocalStorage.token();
    const userProfile = useSelector((state) => state?.user?.profile);

    const isAuthenticated = sessionKey && auth;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const navigate = useNavigate();

    // Toggle the menu open/close
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handle menu close action
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Handle logout action
    const handleLogout = () => {
        const data = {
            sessionKey: sessionKey,
        };

        props
            .logout(data)
            .then(async (sessionRes) => {
                setAnchorEl(null);

                // Create a new session if needed
                let sessionKey = "";
                sessionKey = uuidv4();
                const session = { sessionKey: sessionKey };
                props.getSession(session).then((ress) => {
                    // Clear session storage
                    setTimeout(() => {
                        navigate("/logIn");
                        storage.destroyFromLocalStorage.token();
                    }, 2000);
                    // storage.destroyFromSessionStorage.sessionKey();
                    toast.success("Logout successful!", { autoClose: 3000 });
                }); // Store session data in redux
            })
            .catch((error) => {
                toast.error("Error during logout. Please try again.", {
                    autoClose: 4000,
                });
            });
    };

    // Handle navigate to profile page
    const handleNavigate = () => {
        navigate("/profile");
        setAnchorEl(null); // Close the menu after navigating
    };

    return (
        <Box sx={Style.box1}>
            {isAuthenticated ? (
                <>
                    <Button
                        onClick={handleClick}
                        sx={Style.btn1}
                        endIcon={
                            isMenuOpen ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )
                        }
                    >
                        <Avatar sx={Style.avtar1} />
                        {userProfile?.userName || ""}
                    </Button>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem onClick={handleNavigate}>My Account</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </>
            ) : (
                <Button
                    variant="outlined"
                    sx={Style.navBtn}
                    component={Link}
                    to="/logIn"
                >
                    LOGIN
                </Button>
            )}
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logout: (data) => dispatch(logout(data)),
    getSession: (data) => dispatch(session(data)),
});

export default connect(null, mapDispatchToProps)(NavbarIcons);

// Define styles for the NavbarIcons component
const Style = {
    navBtn: {
        borderRadius: "30px",
        padding: "10px 20px",
        border: "1px solid",
        color: "rgba(251, 176, 64, 1)",
        marginRight: "20px",
        textTransform: "none",
        fontSize: "0.875rem",
        display: { xs: "block", sm: "block" },
        color: "black",
        bgcolor: "lightgrey",
        border: "none",
        fontWeight: "bold",
        "&:hover": {
            bgcolor: "lightgrey",
            color: "black",
            border: "none",
        },
    },

    btn1: {
        textTransform: "none",
        color: "black",
        fontWeight: "bold",
    },

    avtar1: {
        bgcolor: "lightgrey",
        width: 32,
        height: 32,
        mr: 1,
    },

    box1: {
        marginLeft: "auto",
    },
};
