// Import yup for schema validation
import * as yup from "yup";

// Define the validation schemas
export const LoginFormSchema = {
    // Schema for the login form
    Login: yup.object().shape({
        // Validation rules for the email field
        email: yup
            .string()
            .required("Email is required.")
            .email("Email is invalid"),

        // Validation rules for the password field
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters long."),
    }),
};
