import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Card, CardContent, Avatar, Grid } from "@mui/material";
import HeaderText from "../../components/Custom/Header/HeaderText";
import { storage } from "../../config/storage";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile } from "../../redux/Slices/userSlice";
import { connect } from "react-redux";

const Profile = (props) => {
    // Redux dispatch and user profile state
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.user.profile);

    // State variables to store user information
    const [userName, setName] = useState("");
    const [userEmail, setEmail] = useState("");

    // Fetch user profile data when the component mounts
    useEffect(() => {
        const sessionKey = storage.fetchFromSessionStorage.sessionKey();
        const auth = storage.fetchFromLocalStorage.token();

        // Data object for API request
        const data = {
            sessionKey: sessionKey,
        };

        // Dispatch the action to fetch user profile data
        props.getUserProfile(data);
    }, [dispatch]);

    // Update state when userProfile changes
    useEffect(() => {
        if (userProfile) {
            setName(userProfile.userName);
            setEmail(userProfile.userEmail);
        }
        console.log(userProfile);
    }, [userProfile]);

    return (
        <>
            <HeaderText Heading={"Dashboard"} />

            <div style={Style.main1}>
                <Typography variant="h5" gutterBottom sx={Style.typography}>
                    My Account
                </Typography>

                <Card variant="outlined" style={Style.Card1}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Avatar
                                    alt={userName}
                                    src="https://via.placeholder.com/150"
                                    sx={Style.avatar}
                                />
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            <strong>Name:</strong> {userName}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            <strong>Email:</strong> {userEmail}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

let mapDispatchToProps = (dispatch) => ({
    getUserProfile: (data) => dispatch(fetchUserProfile(data)),
});
export default connect(null, mapDispatchToProps)(Profile);

// Styles object for inline styling
const Style = {
    main1: {
        padding: "100px",
    },
    typography: {
        fontWeight: 700,
        backgroundColor: "#f5f5f5",
        height: "50px",
        padding: "11px",
    },
    Card1: {
        width: "100%",
    },
    avatar: {
        width: 60,
        height: 60,
    },
};
