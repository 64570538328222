import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { storage } from "../config/storage";

const ProtectedRoutes = () => {
    // Retrieve sessionId and user_id from session storage
    const sessionId = storage.fetchFromSessionStorage.sessionKey();
    const token = storage.fetchFromLocalStorage.token();

    // Conditional rendering based on sessionId and userId
    const isAuthenticated = sessionId && token;

    return (
        // If both sessionId and userId exist, render the child routes (Outlet)
        isAuthenticated ? <Outlet /> : <Navigate to={"/"} />
    );
};

export default ProtectedRoutes;
