import React, { useEffect, useRef } from "react";
import InputField from "../../components/Custom/Input/InputField";
import { Grid, Paper } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import Image2 from "../../images/Rectangle 3.png";
import { LoginFormSchema } from "./LoginFormSchema";
import CustomButton from "../../components/Custom/Button/CustomButton";
import { storage } from "../../config/storage";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { login } from "../../redux/Slices/userSlice";
import { createUserActivity } from "../../redux/Slices/userActivitySlice";
import { toast } from "react-toastify";

// Define the Login component
const Login = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const sessionKey = storage.fetchFromSessionStorage.sessionKey();
    const isActivityCreated = useRef(false);

    const loginData = useSelector((state) => state.login);

    /* The useEffect hook checks if the session is created and if the user activity has already been sent
     If not, it sends the user activity and updates the ref to prevent multiple registers of same activity */

    useEffect(() => {
        const sessionKey = storage.fetchFromSessionStorage.sessionKey();

        if (sessionKey && !isActivityCreated.current) {
            const data = {
                sessionKey: sessionKey,
                location: location,
            };

            props.createUserActivity(data); // Dispatch the thunk using props
            isActivityCreated.current = true;
        }
        return () => {
            toast.dismiss();
        };
    }, [sessionKey]);

    const handleSubmit = async (values) => {
        const sessionKey = storage.fetchFromSessionStorage.sessionKey();
        const data = {
            userEmail: values.email,
            userPassword: values.password,
            sessionKey: sessionKey,
        };

        const resultAction = await props.getLoggedIn(data);
        if (login.fulfilled.match(resultAction)) {
            // Navigate on successful login
            navigate("/profile");
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} style={Style.mainGrid}>
                    <Paper sx={Style.paperStyle}>
                        <Typography
                            variant="h4"
                            align="center"
                            gutterBottom
                            sx={Style.typography}
                        >
                            LOGIN
                        </Typography>
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={LoginFormSchema.Login}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Box>
                                        <InputField
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.email &&
                                                Boolean(errors.email)
                                            }
                                            helperText={
                                                touched.email && errors.email
                                            }
                                            FormHelperTextProps={{
                                                sx: {
                                                    marginLeft: "0px",
                                                },
                                            }}
                                        />
                                    </Box>

                                    <Box sx={Style.Box1}>
                                        <InputField
                                            label="Password"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            name="password"
                                            type="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.password &&
                                                Boolean(errors.password)
                                            }
                                            helperText={
                                                touched.password &&
                                                errors.password
                                            }
                                            FormHelperTextProps={{
                                                sx: {
                                                    marginLeft: "0px",
                                                },
                                            }}
                                        />
                                    </Box>

                                    <Box sx={Style.Box2}>
                                        <CustomButton
                                            type="submit"
                                            children={"Login"}
                                        />
                                    </Box>
                                    <Box sx={Style.Box3}>
                                        <Typography variant="body2">
                                            Don't have an account yet?{" "}
                                            <Link
                                                to="/signUp"
                                                style={Style.link}
                                            >
                                                Register{" "}
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} sx={Style.grid}>
                    <img
                        src={Image2}
                        alt="Description of the image"
                        style={Style.img}
                    />
                </Grid>
            </Grid>
        </>
    );
};
let mapDispatchToProps = (dispatch) => ({
    createUserActivity: (data) => dispatch(createUserActivity(data)), // Map the createUserActivity thunk to props
    getLoggedIn: (data) => dispatch(login(data)),
});

export default connect(null, mapDispatchToProps)(Login);

const Style = {
    mainGrid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    paperStyle: {
        marginTop: { xs: 20, lg: "0%" },
        padding: "20px",
        maxWidth: "400px",
        width: "100%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)", // Set equal shadow on all sides
    },

    Box1: {
        mt: "20px",
    },

    Box2: {
        mt: "30px",
        textAlign: "center",
    },

    Box3: {
        mt: "20px",
        textAlign: "center",
    },

    link: {
        color: "#1976d2",
        textDecoration: "none",
    },

    grid: {
        display: { xs: "none", md: "flex" },
    },

    img: {
        width: "100%",
        height: "100vh",
        objectFit: "cover",
    },

    typography: {
        marginBottom: "30px",
    },
};
