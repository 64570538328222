import React from "react";
import { Button } from "@mui/material";

// Define a CustomButton component that wraps the MUI Button component and allows for customization through various props.
const CustomButton = ({
    children,
    type = "button",
    variant = "contained",
    color = "primary",
    fullWidth = true,
    disabled = false,
    sx = {},
    onClick,
    ...props
}) => {
    return (
        <Button
            type={type}
            variant={variant}
            color={color}
            fullWidth={fullWidth}
            disabled={disabled}
            onClick={onClick}
            sx={{
                color: "black",
                bgcolor: "lightgrey",
                "&:hover": {
                    bgcolor: "lightgrey",
                    color: "black",
                },
                ...sx,
            }}
            // Spread any additional props onto the MUI Button component
            {...props}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
