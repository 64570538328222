import React, { useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import HeaderText from "../../components/Custom/Header/HeaderText";
import { useLocation } from "react-router-dom";
import { createUserActivity } from "../../redux/Slices/userActivitySlice"; // Import the thunk
import { storage } from "../../config/storage";
import { toast } from "react-toastify";

// Define the Home component
const Home = (props) => {
    const location = useLocation();
    const isActivityCreated = useRef(false);
    const sessionKey = storage.fetchFromSessionStorage.sessionKey();
    const isSession = useSelector((state) => state.session.isSession);

    useEffect(() => {
        const data = {
            sessionKey: sessionKey,
            location: location,
        };
        if (!isActivityCreated.current && isSession) {
            props.createUserActivity(data); // Dispatch the thunk using props
            isActivityCreated.current = true;
        }
        return () => {
            toast.dismiss();
        };
    }, [isSession]);

    return (
        <>
            <HeaderText Heading={"Home"} />
            <p style={{padding:'50px'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ac nisi eget sapien facilisis fermentum. Cras luctus, erat a
                feugiat lacinia, ex odio cursus mi, at vestibulum urna ligula
                eget augue. Nulla facilisi. Curabitur nec ligula ut est interdum
                commodo. Aliquam erat volutpat. Donec ac sem sit amet eros
                ultricies commodo. Vivamus vel metus nec mauris suscipit
                viverra. Fusce id justo id magna blandit viverra. Aenean
                condimentum nec turpis et dapibus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ac nisi eget sapien facilisis fermentum. Cras luctus, erat a
                feugiat lacinia, ex odio cursus mi, at vestibulum urna ligula
                eget augue. Nulla facilisi. Curabitur nec ligula ut est interdum
                commodo. Aliquam erat volutpat. Donec ac sem sit amet eros
                ultricies commodo. Vivamus vel metus nec mauris suscipit
                viverra. Fusce id justo id magna blandit viverra. Aenean
                condimentum nec turpis et dapibus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ac nisi eget sapien facilisis fermentum. Cras luctus, erat a
                feugiat lacinia, ex odio cursus mi, at vestibulum urna ligula
                eget augue. Nulla facilisi. Curabitur nec ligula ut est interdum
                commodo. Aliquam erat volutpat. Donec ac sem sit amet eros
                ultricies commodo. Vivamus vel metus nec mauris suscipit
                viverra. Fusce id justo id magna blandit viverra. Aenean
                condimentum nec turpis et dapibus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ac nisi eget sapien facilisis fermentum. Cras luctus, erat a
                feugiat lacinia, ex odio cursus mi, at vestibulum urna ligula
                eget augue. Nulla facilisi. Curabitur nec ligula ut est interdum
                commodo. Aliquam erat volutpat. Donec ac sem sit amet eros
                ultricies commodo. Vivamus vel metus nec mauris suscipit
                viverra. Fusce id justo id magna blandit viverra. Aenean
                condimentum nec turpis et dapibus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ac nisi eget sapien facilisis fermentum. Cras luctus, erat a
                feugiat lacinia, ex odio cursus mi, at vestibulum urna ligula
                eget augue. Nulla facilisi. Curabitur nec ligula ut est interdum
                commodo. Aliquam erat volutpat. Donec ac sem sit amet eros
                ultricies commodo. Vivamus vel metus nec mauris suscipit
                viverra. Fusce id justo id magna blandit viverra. Aenean
                condimentum nec turpis et dapibus.
            </p>
        </>
    );
};

// Define mapDispatchToProps to map the dispatch to props
const mapDispatchToProps = (dispatch) => ({
    createUserActivity: (data) => dispatch(createUserActivity(data)), // Map the createUserActivity thunk to props
});

// Connect the Home component to the Redux store
export default connect(null, mapDispatchToProps)(Home);
