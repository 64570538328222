import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Slices/userSlice"; // Import the default reducer
import sessionReducer from "./Slices/sessionSlice";
import userActivityReducer from "./Slices/userActivitySlice";
// import profileReducer from "./Slices/userProfileSlice";

export const store = configureStore({
    reducer: {
        user: userReducer, // Register the login slice reducer
        session: sessionReducer, // Register the session slice reducer
        userActivity: userActivityReducer, // Register the userActivity slice reducer
        // profile: profileReducer, // Register the profile slice reducer
    },
    devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development only
});

export default store;
