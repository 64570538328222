import { Api } from "../config/request";

// API function to create a session
const requestCreateSession = async (sessionData) => {
    let response = await Api.PostRequest("session/createSession", sessionData);
    return response;
};

const sessionApi = {
    requestCreateSession,
};

export default sessionApi;
