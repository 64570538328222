// Define keys for local and session storage
const LS_KEY = {
    token: "token",
};

const SS_KEY = {
    sessionKey: "sessionKey",
};

// Local storage functions
const setInLocalStorage = {
    token: (data) => {
        localStorage.setItem(
            LS_KEY.token,
            `${data}` /* "Bearer" + " " + data */
        );
    },
};

const fetchFromLocalStorage = {
    token: () => {
        return localStorage.getItem(LS_KEY.token);
    },
};

const destroyFromLocalStorage = {
    token: () => {
        return localStorage.removeItem(LS_KEY.token);
    },
};

// Session storage functions
const setInSessionStorage = {
    sessionKey: (data) => {
        sessionStorage.setItem(
            SS_KEY.sessionKey,
            data /* "Bearer" + " " + data */
        );
    },
};

const fetchFromSessionStorage = {
    sessionKey: () => {
        return sessionStorage.getItem(SS_KEY.sessionKey);
    },
};

const destroyFromSessionStorage = {
    sessionKey: () => {
        sessionStorage.removeItem(SS_KEY.sessionKey);
    },
};

export const storage = {
    setInLocalStorage,
    fetchFromLocalStorage,
    destroyFromLocalStorage,

    setInSessionStorage,
    fetchFromSessionStorage,
    destroyFromSessionStorage,
};
