// src/redux/Slices/authSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { storage } from "../../config/storage";
import handleAsyncThunk from "../handleAsyncThunk";
import userApi from "../../services/userApi";

// Register async thunk
export const register = createAsyncThunk(
    "user/register",
    (data, { rejectWithValue }) => {
        return userApi
            .requestUserSignUp(data)
            .then((response) => {
                if (response.status === 0) {
                    storage.setInLocalStorage.token(response.data.token);
                    toast.success("Sign-up successful", { autoClose: 1500 });
                    return response.data; // Return the response data on success
                } else {
                    toast.error(response.message, { autoClose: 3000 });
                    return rejectWithValue(response.message); // Reject with error message
                }
            })
            .catch((error) => {
                toast.error("An unexpected error occurred", {
                    autoClose: 3000,
                });
                return rejectWithValue(error.message); // Handle unexpected errors
            });
    }
);

// Login async thunk
export const login = createAsyncThunk(
    "user/login",
    (data, { rejectWithValue }) => {
        return userApi
            .requestUserSignIn(data)
            .then((response) => {
                console.log(response);

                if (response.status === 0) {
                    storage.setInLocalStorage.token(response.data.token);
                    setTimeout(()=> {
                      toast.success("Sign-in successful", { autoClose: 3500 });
                    }, 1000)
                    
                    return response.data; // Return the response data on success
                } else {
                    toast.error(response.message, { autoClose: false });
                    return rejectWithValue(response.message); // Reject with error message
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error("An unexpected error occurred", {
                    autoClose: 3000,
                });
                return rejectWithValue(error.message); // Handle unexpected errors
            });
    }
);

// Logout async thunk
export const logout = createAsyncThunk(
    "user/logout",
    (data, { rejectWithValue }) => {
        return userApi
            .requestUserSignOut(data)
            .then((response) => {
                storage.destroyFromSessionStorage.sessionKey();
                storage.destroyFromLocalStorage.token();
               // toast.success("Logout successful!", { autoClose: 3000 });
                return response.data; // Return the response data on success
            })
            .catch((error) => {
                toast.error("Failed to log out. Please try again.", {
                    autoClose: 3000,
                });
                return rejectWithValue(error.message); // Handle and reject the error
            });
    }
);
// Thunk to fetch user profile data
export const fetchUserProfile = createAsyncThunk(
    "profile/fetchUserProfile",
    async (data, { rejectWithValue }) => {
        return userApi
            .requestUserProfile(data)
            .then((response) => {
                // Check response status
                if (response.status === 0) {
                    return response.data; // Return the response data on success
                } else {
                    toast.error(response.message, { autoClose: 3000 });
                    return rejectWithValue(response.message); // Reject with error message
                }
            })
            .catch((error) => {
                toast.error("An unexpected error occurred", {
                    autoClose: 3000,
                });
                return rejectWithValue(error.message); // Handle unexpected errors
            });
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: {
        isLoggedIn: false,
        userId: null,
        status: null,
        error: null,
        isSession: false,
    },
    reducers: {
        // Define any additional reducers if needed
    },
    extraReducers: (builder) => {
        handleAsyncThunk(builder, register, "userId");
        handleAsyncThunk(builder, login, "userId");
        handleAsyncThunk(builder, logout, "isLoggedOut"); // Use builder notation
        handleAsyncThunk(builder, fetchUserProfile, "profile"); // Use builder notation to handle async thunk
    },
});

export default userSlice.reducer;
