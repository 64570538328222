import React from "react";
import { Box, Typography } from "@mui/material";

// HeaderText component renders a styled heading with customizable text
const HeaderText = ({ Heading }) => {
    return (
        <>
            <Box sx={Style.Box1}>
                <Typography variant="h4" component="h1" sx={Style.Typography1}>
                    {Heading}
                </Typography>
            </Box>
        </>
    );
};

export default HeaderText;

// Style object defining custom styles for the Box and Typography components
const Style = {
    Box1: {
        backgroundColor: "#f5f5f5",
        padding: "50px",
        mt: "70px",
    },
    Typography1: {
        fontWeight: 700,
    },
};
