// src/redux/handleAsyncThunk.js

const handleAsyncThunk = (builder, thunk, dataKey) => {
    builder
        .addCase(thunk.pending, (state) => {
            state.status = "Fetching data. Please wait a moment...";
        })
        .addCase(thunk.rejected, (state, action) => {
            state.status = "Failed to fetch data...";
            state.error = action.error.message;
        })
        .addCase(thunk.fulfilled, (state, action) => {
            state.status = null;
            state[dataKey] = action.payload;
            state.error = null;
            state.isSession = true;
        });
};

export default handleAsyncThunk;
