/* This file contains API functions for handling user authentication and profile operations.
 It provides methods for signing in, fetching user profiles, and signing out. */

import { Api } from "../config/request";

// Sends a POST request to the "user/signIn" endpoint with the provided data.
const requestUserSignIn = async (data) => {
    let response = await Api.PostRequest("user/signIn", data);
    return response.data;
};

// Sends a POST request to the "user/signUp" endpoint with the provided data.
const requestUserSignUp = async (data) => {
    let response = await Api.PostRequest("user/signUp", data);
    return response.data;
};

// Sends a POST request to the "user/profile" endpoint with the provided data.
const requestUserProfile = async (data) => {
    let response = await Api.PostRequest("user/profile", data);
    return response.data;
};

// Sends a POST request to the "user/signOut" endpoint with the provided data.
const requestUserSignOut = async (data) => {
    let response = await Api.PostRequest("user/signOut", data);
    return response.data;
};

const userApi = {
    requestUserSignIn,
    requestUserSignUp,
    requestUserProfile,
    requestUserSignOut,
};

export default userApi;
