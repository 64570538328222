import { useEffect, useRef, useState } from "react";
import { useSelector, connect } from "react-redux";
import { session } from "./redux/Slices/sessionSlice";
import { storage } from "./config/storage";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import { v4 as uuidv4 } from "uuid";

const App = (props) => {
    const isSession = useSelector((state) => state.session.isSession);
    const [isSessionCreated, setIsSessionCreated] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return; // Skip effect if already mounted

        isMounted.current = true; // Mark as mounted

        const initializeSession = async () => {
            const sessionKey = storage.fetchFromSessionStorage.sessionKey();

            // Check session key and state to avoid redundant session creation
            if (!sessionKey && !isSession && !isSessionCreated) {
                let sessionKey = "";
                sessionKey = uuidv4();
                const res = { sessionKey: sessionKey };
                setIsSessionCreated(true);
                props.getSession(res);
            }
        };

        initializeSession();
    }, []); // Include necessary dependencies

    return (
        <BrowserRouter>
            <MainRoutes />
        </BrowserRouter>
    );
};

// All Dispatches
const mapDispatchToProps = (dispatch) => ({
    getSession: (data) => dispatch(session(data)),
});

export default connect(null, mapDispatchToProps)(App);
