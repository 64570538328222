// Import yup for schema validation
import * as yup from "yup";

// Define the validation schemas
export const SignUpFormSchema = {
    // Schema for the sign-up form
    SignUp: yup.object().shape({
        // Validation rules for the username field
        userName: yup.string().required("Name is required"),

        // Validation rules for the email field
        userEmail: yup
            .string()
            .required("Email is required.")
            .email("Email is invalid."),

        // Validation rules for the password field
        userPassword: yup
            .string()
            .required("Password is required.")
            .min(8, "Password must be at least 8 characters long."),

        // Validation rules for the confirm password field
        confirmPassword: yup
            .string()
            .required("Confirm password is required.")
            .oneOf([yup.ref("userPassword"), null], "Passwords must match"),
    }),
};
