import axios from "axios";
import { apiURL } from "./apiURL";
import { storage } from "./storage";
// This file handles making HTTP POST requests to a specified API endpoint using Axios. Sends a POST request to the given URL with the provided data.

const PostRequest = async (url, data) => {
    try {
        const token = storage.fetchFromLocalStorage.token();

        const res = await axios({
            url: apiURL + url,
            method: "POST",
            data,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token ? ` ${token}` : "", // Add the token to the headers if available
            },
        });
        return res ? res : res.data;
    } catch (err) {
        console.log(err);
    }
};

export const Api = {
    PostRequest,
};
